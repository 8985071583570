<i18n locale="th" lang="yaml" src="@i18n/service/service.th.yaml"></i18n>
<i18n locale="th" lang="yaml" src="@i18n/service/pre-delivery.th.yaml"></i18n>
<i18n locale="th" lang="yaml" src="@i18n/service/onfield-service.th.yaml"></i18n>
<i18n locale="th" lang="yaml">
onfield_pre_delivery.footer.signature : "<div>..................................</div>"
onfield_pre_delivery.sign_warning : "กรุณาลงลายมือชื่อผู้เข้ารับบริการ และผู้ส่งมอบให้ครบถ้วน"

</i18n>

<template>
	<a-modal centered width="800px" :visible="visible" :footer="null" :mask-closable="false" @cancel="handleClose">
		<a-spin wrapper-class-name="tunjai-page-loading" :spinning="loading" size="large" :tip="$t('pre_delivery.drone.test.result.pageLoading.tip')">
			<div id="print-pre-delivery" class="pre-delivery-modal">
				<div class="page-header">
					<my-page-header :title="$t('pre_delivery.title', { name: serviceNumber })" />
					<div>{{ $t('pre_delivery.header.page_date', { datetime: printDate }) }}</div>
				</div>

				<div class="service-detail-container">
					<div class="service-col">
						<label>{{ $t('pre_delivery.header.company_info') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								{{ displayCompany.name }}
							</div>
							<div class="service-val-row">
								{{
									(displayCompany.address ? displayCompany.address + ' ' : '') + displayCompany.province + ' ' + displayCompany.postal_code
								}}
							</div>
							<div v-if="displayCompany.contact_phone" class="service-val-row">
								<PhoneLink :value="displayCompany.contact_phone" :view-only="true" />
							</div>
						</div>
					</div>
					<div class="service-col">
						<label>{{ $t('pre_delivery.header.drone') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								{{ $t('pre_delivery.header.drone.serial', { serial: droneSerial }) }}
							</div>
							<div class="service-val-row">
								{{ $t('pre_delivery.header.drone.pixhawk', { pixhawk: dronePixhawk }) }}
							</div>
							<div class="service-val-row">
								{{ $t('pre_delivery.header.drone.model', { name: droneModelName }) }}
							</div>
						</div>
					</div>
					<div class="service-col">
						<label>{{ $t('service.field.customer') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								{{ customerName }}
							</div>
							<div class="service-val-row">
								<PhoneLink :value="customerPhone" :view-only="true" />
								<span style="margin-left: 8px">{{ $t('common.province') }}{{ ' ' + customerProvince }}</span>
							</div>
						</div>
					</div>
					<div class="service-col">
						<label>{{ $t('pre_delivery.header.drone_receive_date') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								<span v-if="droneReceiveDateTime">
									{{ droneReceiveDateTime }}
								</span>
								<span v-else>
									{{ $t('common.unspecify') }}
								</span>
							</div>
						</div>
					</div>
					<div class="service-col">
						<label>{{ $t('pre_delivery.header.inspection_date') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								<span v-if="inspectedDateTime">
									{{ inspectedDateTime }}
								</span>
								<span v-else>
									{{ $t('common.unspecify') }}
								</span>
							</div>
						</div>
					</div>
					<div class="service-col">
						<label>{{ $t('pre_delivery.header.flight_time') }}</label>
						<div class="service-val">
							<FlightTimeView :time-seconds="flightTime" />
						</div>
					</div>
				</div>

				<BlockPane :block="isNotInspect || isDeviceOnly">
					<span v-if="isDeviceOnly" slot="message">
						{{$t('pre_delivery.drone.none')}}
					</span>
					<span v-else-if="isNotInspect" slot="message">
						{{$t('pre_delivery.field.is_not_inspect.message')}}
					</span>
					<PreDeliveryTGD1XChecklistPrint ref="checklistRef" :can-update="false" :service="service" />
				</BlockPane>
				<div class="consent" v-html="$t('pre_delivery.footer.consent.general')" />
				<div v-html="$t('pre_delivery.footer.consent.insurance')" />
				<a-checkbox v-model="isConsentChecked" class="consent-check">
					<div v-html="$t('onfield_pre_delivery.footer.consent.acceptance')" />
				</a-checkbox>
				<div class="signature">
					<div>
						<div v-if="!signData.customer.img" id="customer" class="sign-area" v-html="$t('onfield_pre_delivery.footer.signature')" />
						<div v-else class="sign-area">
							<img :src="signData.customer.img" class="signature-img">
						</div>
						<div v-if="!signData.customer.name" id="customerName" class="signature-item" v-html="$t('pre_delivery.footer.name')"/>
						<div v-else class="signature-item" v-html="`(${signData.customer.name})`"/>
						<div class="signature-item" v-html="$t('pre_delivery.footer.signature.customer')" />
						<div class="sign-button">
							<a-button id="customerBt" @click="openSignModal($refs.customerRef)"> {{ $t('action.sign') }} </a-button>
						</div>
					</div>
					<div>
						<div v-if="!signData.inspector.img" id="inspector" class="sign-area" v-html="$t('onfield_pre_delivery.footer.signature')" />
						<div v-else class="sign-area">
							<img :src="signData.inspector.img" class="signature-img">
						</div>
						<div v-if="!signData.inspector.name" id="inspectorName" class="signature-item" v-html="$t('pre_delivery.footer.name')" />
						<div v-else class="signature-item" v-html="`(${signData.inspector.name})`"/>
						<div class="signature-item" v-html="$t('pre_delivery.footer.signature.inspect_by')" />
						<div class="sign-button">
							<a-button id="inspectorBt" @click="openSignModal($refs.inspectorRef)"> {{ $t('action.sign') }} </a-button>
						</div>
					</div>
					<div>
						<div v-if="!signData.receiver.img" id="receiver" class="sign-area" v-html="$t('onfield_pre_delivery.footer.signature')" />
						<div v-else class="sign-area">
							<img :src="signData.receiver.img" class="signature-img">
						</div>
						<div v-if="!signData.receiver.name" id="receiverName" class="signature-item" v-html="$t('pre_delivery.footer.name')" />
						<div v-else class="signature-item" v-html="(`(${signData.receiver.name})`)"/>
						<div class="signature-item" v-html="$t('pre_delivery.footer.signature.deliver_by')" />
						<div class="sign-button">
							<a-button id="receiverBt" @click="openSignModal($refs.receiverRef)"> {{ $t('action.sign') }} </a-button>
						</div>
					</div>
					<div>
						<div v-if="!signData.approver.img" id="approver" class="sign-area" v-html="$t('onfield_pre_delivery.footer.signature')" />
						<div v-else class="sign-area">
							<img :src="signData.approver.img" class="signature-img">
						</div>
						<div v-if="!signData.approver.name" id="approverName" class="signature-item" v-html="$t('pre_delivery.footer.name')" />
						<div v-else class="signature-item" v-html="(`(${signData.approver.name})`)"/>
						<div class="signature-item" v-html="$t('pre_delivery.footer.signature.approve_by')" />
						<div class="sign-button">
							<a-button id="approverBt" @click="openSignModal($refs.approverRef)"> {{ $t('action.sign') }} </a-button>
						</div>
					</div>
				</div>
				<div class="page-remark">
					{{ $t('pre_delivery.footer.remark') + (remark ? remark : '') }}
				</div>
			</div>
			<div class="page-action-right next-button">
				<a-button type="primary" @click="handleSave"> {{$t('action.save')}} </a-button>
			</div>
			<SignModal ref="customerRef"  @save="handleSign($event, 'customerRef')" />
			<SignModal ref="inspectorRef"  @save="handleSign($event, 'inspectorRef')" />
			<SignModal ref="receiverRef"  @save="handleSign($event, 'receiverRef')" />
			<SignModal ref="approverRef" @save="handleSign($event, 'approverRef')" />
		</a-spin>
	</a-modal>
</template>

<script>
import { mapState } from 'vuex'
import PhoneLink from '@components/common/PhoneLink.vue'
import PreDeliveryTGD1XChecklistPrint from '@components/service/tgd1x/PreDeliveryTGD1XChecklistPrint.vue'
import FlightTimeView from '@components/common/FlightTimeView.vue'
import BlockPane from "@components/common/BlockPane.vue"
import SignModal from '@components/common/SignModal.vue'
import { Checkbox } from 'ant-design-vue'

export default {
	components: {
		'a-checkbox': Checkbox,
		PreDeliveryTGD1XChecklistPrint,
		PhoneLink,
		FlightTimeView,
		BlockPane,
		SignModal
	},

	props: {
		service: {
			type: null,

			default: () => [],
		},

		preDelivery: {
			type: null,

			default: () => {},
		},

		isNotInspect: {
			type: Boolean,
			default: false,
		},

		isDeviceOnly: {
			type: Boolean,
			default: false,
		},

		modalLoading: {
			type: Boolean,
			default: false,
		}
	},

	data() {
		return {
			visible: false,
			loading: false,
			flightTime: '',
			droneReceiveDateTime: '',
			inspectedDateTime: '',
			serviceId: '',
			serviceNumber: '',
			droneSerial: '',
			dronePixhawk: '',
			droneModelName: '',
			customerName: '',
			customerPhone: '',
			customerProvince: '',
			printDate: '',
			remark: '',
			isConsentChecked: true,
			signData: {
				customer: {
					img: null,
					name: null
				},
				inspector: {
					img: null,
					name: null
				},
				receiver: {
					img: null,
					name: null
				},
				approver: {
					img: null,
					name: null
				}
			},
			isDocumentSaved: false,
		}
	},

	computed: {
		...mapState('user', ['companies']),
		...mapState('auth', ['currentCompany']),
		displayCompany() {
			return this.companies.find((c) => c.id == this.currentCompany.id)
		},
	},
	watch: {
		modalLoading : {
			handler() {
				this.loading = this.modalLoading
			}
		}
	},
	methods: {
		setData(data) {
			this.$nextTick(() => {
				this.$refs.checklistRef.setData(data)
			})
		},
		handleClose() {
			if (this.isDocumentSaved) {
				this.visible = false
				return
			}
			this.$confirm({
				title: this.$t('service.onsite.form.action.update.confirm.title'),
				content: h => <div>{this.$t('service.onsite.form.action.update.confirm.message')}
												<span style="color:red;"><u>{this.$t('service.onsite.form.action.close.confirm.warning')}</u></span>
												{this.$t('service.onsite.form.action.close.confirm.yes_or_no')}
											</div>,
				okText: this.$t('common.ok'),
				cancelText : this.$t('common.cancel'),
				maskClosable: true,
				onOk: () => {
					this.visible = false
				},
			})
		},
		openModal(data) {
			this.visible = true
			this.serviceId = data.serviceId
			this.flightTime = data.flightTime
			this.inspectedDateTime = data.inspectedDateTime
			this.droneReceiveDateTime = data.droneReceiveDateTime
			this.serviceNumber = data.serviceNumber
			this.droneSerial = data.droneSerial
			this.dronePixhawk = data.dronePixhawk
			this.droneModelName = data.droneModelName
			this.customerName = data.customerName
			this.customerPhone = data.customerPhone
			this.customerProvince = data.customerProvince
			this.remark = data.remark
			const currentDateTime = new Date()
			this.printDate = currentDateTime.toLocaleDateString() + ':' + currentDateTime.toLocaleTimeString()
		},
		openSignModal(ref) {
			ref.openSignModal()
		},
		handleSign(val, currentSigner) {
			switch (currentSigner) {
				case 'customerRef':
					this.signData.customer.img = val.img
					this.signData.customer.name = val.name
					break
				case 'inspectorRef':
					this.signData.inspector.img = val.img
					this.signData.inspector.name = val.name
					break
				case 'receiverRef':
					this.signData.receiver.img = val.img
					this.signData.receiver.name = val.name
					break
				case 'approverRef':
					this.signData.approver.img = val.img
					this.signData.approver.name = val.name
			}
		},
		handleSave() {
			if (this.signData.customer.img && this.signData.receiver.img) {
				this.$emit('save', {
					signData: this.signData,
					companyData: this.displayCompany,
					droneReceiveDateTime: this.droneReceiveDateTime,
					inspectedDateTime: this.inspectedDateTime,
					isConsentChecked: this.isConsentChecked,
				} )
				this.isDocumentSaved = true
			} else {
				this.$message.error(this.$t('onfield_pre_delivery.sign_warning'))
			}
		},
	},
}
</script>

<style lang="less" scoped>
@part-break-screen: @screen-lg;
.consent-check {
	display: flex;
}
.sign-area {
	display: block;
	align-items: center;
	width: 123.16px;
	height: 40px;
}
.pre-delivery-modal {
	height: 600px;
	overflow: auto;
	overscroll-behavior: none;
	div {
		font-size: 0.7rem;
	}
}
.page-header {
	display: flex;
	flex-basis: 100%;
	flex-direction: row;
}
.service-detail-container {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	background-color: @white;
	margin-bottom: 8px;
	border-radius: @border-radius-base;

	.service-col {
		flex-basis: 50%;
		display: flex;
		flex-wrap: nowrap;
		min-width: 0;
		border: 1px solid @gray-4;
		> label {
			font-family: @font-family-title;
			color: @primary-color;
			min-width: 100px;
			flex-basis: 100px;
			background-color: @gray-3;
			padding: 4px 8px;
		}
		> .service-val {
			flex-grow: 1;
			padding: 4px 8px;
			min-width: 0;
			.service-val-row {
				margin-bottom: 2px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
.consent {
	margin-top: 16px;
}
.signature {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: relative;
	padding: 10px;
	margin-top: 8px;
	margin-bottom: 0px;
	.signature-item {
		display: flex;
		align-items: center;
		flex-direction: column;
		position: relative;
	}
	.sign-button {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.signature-img {
	display: block;
	width: 100%;
}
.page-remark {
	display: flex;
	margin-top: 8px;
	justify-content: left;
	align-items: left;
}
</style>
