
<i18n locale="th" lang="yaml" src="@i18n/service/pre-delivery.th.yaml"></i18n>

<i18n locale="th" lang="yaml" >
tgd1x.delivery_print.checklist.pass : "ได้"
tgd1x.delivery_print.checklist.fail : "ไม่ได้"

tgd1x.delivery_print.checklist.general.label : "ทดสอบทั่วไป"
tgd1x.delivery_print.checklist.general.nut_bolt_test : "สุ่มทดสอบทอร์คของน็อตใน Drone"
tgd1x.delivery_print.checklist.general.nut_marking : "น็อตไม่เลื่อนจากรอยมาร์ค"
tgd1x.delivery_print.checklist.general.holder_foam : "โฟมล็อคใบครบ"
tgd1x.delivery_print.checklist.general.z30_locking : "Z30 ล็อคแขนแล้วไม่ตก"
tgd1x.delivery_print.checklist.general.z30_status : "Z30 ไม่มีรอยฉีกขาด"
tgd1x.delivery_print.checklist.general.spray_lock_status : "ตัวล็อคแขนสเปรย์ใช้งานได้"
tgd1x.delivery_print.checklist.general.propeller_position : "ใบพัดไม่ทับลูกศรบนมอเตอร์"
tgd1x.delivery_print.checklist.general.propeller_status : "ใบพัดไม่บิ่น/ร้าว"
tgd1x.delivery_print.checklist.general.battery_lock_status : "แบตเตอรี่รัดแน่น"
tgd1x.delivery_print.checklist.general.cable_condition : "เก็บสายไฟภายนอกเรียบร้อย"
tgd1x.delivery_print.checklist.general.pump_condition : "ปั๊มทำงานตามปกติ"

tgd1x.delivery_print.checklist.general.nozzle_model : "หัวฉีด"
tgd1x.delivery_print.checklist.general.nozzle_color : "สี"
tgd1x.delivery_print.checklist.general.nozzle_flow_rate : "อัตราไหล"
tgd1x.delivery_print.checklist.general.nozzle_flow_rate.suffix : "ลิตร/นาที"

tgd1x.delivery_print.checklist.flight_manual.label : "ทดสอบบินด้วยมือ + HGMC"
tgd1x.delivery_print.checklist.flight_manual.hgmc_connection : "เชื่อมต่อ HGMC ได้"
tgd1x.delivery_print.checklist.flight_manual.hgmc_sync_compass : "หมุนเครื่อง เช็คเข็มทิศใน HGMC หันหน้าถูกต้อง"
tgd1x.delivery_print.checklist.flight_manual.light_status_green : "ไฟ Status เป็นสีเขียว"
tgd1x.delivery_print.checklist.flight_manual.light_safety_red : "ไฟ Safety เป็นสีแดง"
tgd1x.delivery_print.checklist.flight_manual.power_on_stop_normal : "ติดเครื่องแล้วดับเครื่องปกติได้"
tgd1x.delivery_print.checklist.flight_manual.power_on_stop_emergency : "ติดเครื่องแล้วดับเครื่องฉุกเฉินได้"
tgd1x.delivery_print.checklist.flight_manual.drone_stablity : "ติดเครื่อง นำเครื่องขึ้นแล้วไม่หมุน"
tgd1x.delivery_print.checklist.flight_manual.drone_controller : "บิน (ซ้าย/ขวา/หน้า/หลัง) ถูกต้อง"
tgd1x.delivery_print.checklist.flight_manual.camera_switching : "สลับกล้อง หน้า/ล่าง ได้"
tgd1x.delivery_print.checklist.flight_manual.pump_during_flight_control : "เปิด/ปิด ปั๊มได้ (ขณะกำลังบิน)"
tgd1x.delivery_print.checklist.flight_manual.tank_sensor_status : "แท๊งก์เซนเซอร์ทำงาน น้ำหมดแล้วตัด (ขณะบิน Manual GPS) [เปิดน้ำและรอจนน้ำหมดแล้วแท็งก์เซนเซอร์ตัด]"
tgd1x.delivery_print.checklist.flight_manual.laser_altitude_control : "เปิดเลเซอร์บินไปข้างหน้าแล้วเครื่องไม่ตกความสูง"
tgd1x.delivery_print.checklist.flight_manual.vibration : "ค่าความสั่นสะเทือน (ไม่เกิน 5)"
tgd1x.delivery_print.checklist.flight_manual.vibration_x : "X"
tgd1x.delivery_print.checklist.flight_manual.vibration_y : "Y"
tgd1x.delivery_print.checklist.flight_manual.vibration_z : "Z"
tgd1x.delivery_print.checklist.flight_manual.laser : "ค่าเลเซอร์สูงสุดที่อ่านได้"
tgd1x.delivery_print.checklist.flight_manual.laser_max_value_1 : "Laser 1"
tgd1x.delivery_print.checklist.flight_manual.laser_max_value_2 : "Laser 2"
tgd1x.delivery_print.checklist.flight_manual.power_off_stop_normal : "ลงจอดแล้วดับเครื่องปกติได้"
tgd1x.delivery_print.checklist.flight_manual.nut_bolt_test : "ตรวจสอบสกรูน็อตไม่เลื่อน แขนและโครงสร้างไม่หลวม"

tgd1x.delivery_print.checklist.flight_auto.test1 : "#1"
tgd1x.delivery_print.checklist.flight_auto.test2 : "#2"

tgd1x.delivery_print.checklist.flight_auto.label : "ทดสอบบินด้วยระบบอัตโนมัติ"
tgd1x.delivery_print.checklist.flight_auto.flight_plan_transfer : "ส่งแผนการบินไม่ติดขัด"
tgd1x.delivery_print.checklist.flight_auto.drone_stablity : "เครื่องไม่หมุนตอนบินขึ้น"
tgd1x.delivery_print.checklist.flight_auto.start_plan : "เริ่มทำภารกิจได้ปกติ"
tgd1x.delivery_print.checklist.flight_auto.plan_path_correct : "บินอัตโนมัติตามแผนที่วางไว้"
tgd1x.delivery_print.checklist.flight_auto.parking_water_by_plan : "น้ำหมดตามแผน กลับบ้านลงจอดแบบอัตโนมัติ"
tgd1x.delivery_print.checklist.flight_auto.parking_water_during_flight : "น้ำหมดระหว่างแผน กลับบ้านลงจอดแบบอัตโนมัติ"
tgd1x.delivery_print.checklist.flight_auto.stop_auto : "ดับเครื่องอัตโนมัติได้"
</i18n>

<template>
	<div>
		<div class="pre_delivery-checklist">
			<div class="pre_delivery-checklist-block">
				<div class="checklist-row checklist-row-title checklist-row-border">
					<label class="title"> <span class="topic_no">1</span> {{ $t('tgd1x.delivery_print.checklist.general.label') }} </label>
				</div>

				<ServiceChecklistRowPrint
					v-for="prop in generalCheckboxProps"
					:key="prop"
					v-model="checklistData.general[prop]"
					:label="$t('tgd1x.delivery_print.checklist.general.' + prop)"/>

				<div class="checklist-custom">
					<div>
						<label>{{ $t('tgd1x.delivery_print.checklist.general.nozzle_model') }}</label>
						<label class="text-info"> : {{ checklistData.general.nozzle_model | emptyCheck }} </label>

						<label>{{ $t('tgd1x.delivery_print.checklist.general.nozzle_color') }}</label>
						<label class="text-info"> : {{ checklistData.general.nozzle_color | emptyCheck }} </label>

						<label>{{ $t('tgd1x.delivery_print.checklist.general.nozzle_flow_rate') }}</label>
						<label class="text-info"> : {{ checklistData.general.nozzle_flow_rate | emptyCheck }} </label>
						<label>{{ $t('tgd1x.delivery_print.checklist.general.nozzle_flow_rate.suffix') }}</label>
					</div>
				</div>

				<div class="checklist-row checklist-row-title checklist-row-border">
					<label class="title"> <span class="topic_no">2</span> {{ $t('tgd1x.delivery_print.checklist.flight_manual.label') }} </label>
				</div>

				<ServiceChecklistRowPrint
					v-for="prop in flightManualCheckboxProps"
					:key="prop"
					v-model="checklistData.flight_manual[prop]"
					class="checklist-row-border"
					:label="$t('tgd1x.delivery_print.checklist.flight_manual.' + prop)"/>
			</div>

			<div class="pre_delivery-checklist-block">
				<div class="checklist-custom">
					<label>{{ $t('tgd1x.delivery_print.checklist.flight_manual.vibration') }}</label>
					<div class="checklist-check">
						<label>{{ $t('tgd1x.delivery_print.checklist.flight_manual.vibration_x') }}</label>
						<label class="text-info"> : {{ checklistData.flight_manual.vibration_x | emptyCheck }} </label>
						<label>{{ $t('tgd1x.delivery_print.checklist.flight_manual.vibration_y') }}</label>
						<label class="text-info"> : {{ checklistData.flight_manual.vibration_y | emptyCheck }} </label>
						<label>{{ $t('tgd1x.delivery_print.checklist.flight_manual.vibration_z') }}</label>
						<label class="text-info"> : {{ checklistData.flight_manual.vibration_z | emptyCheck }} </label>
					</div>
				</div>
				<div class="checklist-custom">
					<label>{{ $t('tgd1x.delivery_print.checklist.flight_manual.laser') }}</label>
					<div class="checklist-check">
						<label>{{ $t('tgd1x.delivery_print.checklist.flight_manual.laser_max_value_1') }}</label>
						<label class="text-info"> : {{ checklistData.flight_manual.laser_max_value_1 | emptyCheck }} </label>
						<label>{{ $t('tgd1x.delivery_print.checklist.flight_manual.laser_max_value_2') }}</label>
						<label class="text-info"> : {{ checklistData.flight_manual.laser_max_value_2 | emptyCheck }} </label>
					</div>
				</div>
				<ServiceChecklistRowPrint
					v-model="checklistData.flight_manual.power_off_stop_normal"
					class="checklist-row-border"
					:label="$t('tgd1x.delivery_print.checklist.flight_manual.power_off_stop_normal')"/>

				<ServiceChecklistRowPrint
					v-model="checklistData.flight_manual.nut_bolt_test"
					class="checklist-row-border"
					:label="$t('tgd1x.delivery_print.checklist.flight_manual.nut_bolt_test')"/>
				<div class="checklist-row checklist-row-title checklist-row-border">
					<label class="title"> <span class="topic_no">3</span> {{ $t('tgd1x.delivery_print.checklist.flight_auto.label') }} </label>
					<div class="checklist-check">
						<label class="ant-radio-wrapper">{{ $t('tgd1x.delivery_print.checklist.flight_auto.test1') }}</label>
						<label class="ant-radio-wrapper">{{ $t('tgd1x.delivery_print.checklist.flight_auto.test2') }}</label>
					</div>
				</div>
				<div class="checklist-pair">
					<label class="checklist-label">
						{{ $t('tgd1x.delivery_print.checklist.flight_auto.flight_plan_transfer') }}
					</label>
					<div class="checklist-item">
						{{ checklistData.flight_auto.test1.flight_plan_transfer ? '✓' : '-' }}
					</div>
					<div class="checklist-item">
						{{ checklistData.flight_auto.test2.flight_plan_transfer ? '✓' : '-' }}
					</div>
				</div>
				<div class="checklist-pair">
					<label class="checklist-label">
						{{ $t('tgd1x.delivery_print.checklist.flight_auto.drone_stablity') }}
					</label>
					<div class="checklist-item">
						{{ checklistData.flight_auto.test1.drone_stablity ? '✓' : '-' }}
					</div>
					<div class="checklist-item">
						{{ checklistData.flight_auto.test2.drone_stablity ? '✓' : '-' }}
					</div>
				</div>
				<div class="checklist-pair">
					<label class="checklist-label">
						{{ $t('tgd1x.delivery_print.checklist.flight_auto.start_plan') }}
					</label>
					<div class="checklist-item">
						{{ checklistData.flight_auto.test1.start_plan ? '✓' : '-' }}
					</div>
					<div class="checklist-item">
						{{ checklistData.flight_auto.test2.start_plan ? '✓' : '-' }}
					</div>
				</div>
				<div class="checklist-pair">
					<label class="checklist-label">
						{{ $t('tgd1x.delivery_print.checklist.flight_auto.plan_path_correct') }}
					</label>
					<div class="checklist-item">
						{{ checklistData.flight_auto.test1.plan_path_correct ? '✓' : '-' }}
					</div>
					<div class="checklist-item">
						{{ checklistData.flight_auto.test2.plan_path_correct ? '✓' : '-' }}
					</div>
				</div>
				<div class="checklist-pair">
					<label class="checklist-label">
						{{ $t('tgd1x.delivery_print.checklist.flight_auto.parking_water_by_plan') }}
					</label>
					<div class="checklist-item">
						{{ checklistData.flight_auto.test1.parking_water_by_plan ? '✓' : '-' }}
					</div>
					<div class="checklist-item" />
				</div>
				<div class="checklist-pair">
					<label class="checklist-label">
						{{ $t('tgd1x.delivery_print.checklist.flight_auto.parking_water_during_flight') }}
					</label>
					<div class="checklist-item" />
					<div class="checklist-item">
						{{ checklistData.flight_auto.test2.parking_water_during_flight ? '✓' : '-' }}
					</div>
				</div>
				<div class="checklist-pair">
					<label class="checklist-label">
						{{ $t('tgd1x.delivery_print.checklist.flight_auto.stop_auto') }}
					</label>
					<div class="checklist-item">
						{{ checklistData.flight_auto.test1.stop_auto ? '✓' : '-' }}
					</div>
					<div class="checklist-item">
						{{ checklistData.flight_auto.test2.stop_auto ? '✓' : '-' }}
					</div>
				</div>

				<div class="checklist-row checklist-row-title checklist-row-border">
					<label class="title"> <span class="topic_no">4</span> {{ $t('tgd1x.delivery.checklist.returned_device.label') }} </label>
				</div>
				<div class="return-equiptment">
					<label class="checklist-label">
						{{ '(' + (checklistData.returned_device.is_rc_returned ? '✓' : ' ') + ') ' + $t('tgd1x.delivery.checklist.returned_device.rc') }}
					</label>
				</div>
				<div class="return-equiptment">
					<label class="checklist-label">
						{{ '(' + (checklistData.returned_device.is_tablet_returned ? '✓' : ' ') + ') ' + $t('tgd1x.delivery.checklist.returned_device.tablet') }}
					</label>
				</div>
				<div class="return-equiptment">
					<label class="checklist-label">
						{{ '(' + (checklistData.returned_device.is_charger_returned ? '✓' : ' ') + ') ' + $t('tgd1x.delivery.checklist.returned_device.charger') }}
					</label>
				</div>
				<div class="return-equiptment">
					<label class="checklist-label">
						{{ '(' + (checklistData.returned_device.is_charging_panel_returned ? '✓' : ' ') + ') ' + $t('tgd1x.delivery.checklist.returned_device.charging_panel') }}
					</label>
				</div>
				<div class="return-equiptment">
					<label class="checklist-label">
						{{ $t('tgd1x.delivery.checklist.returned_device.battery1') + ': ' + checklistData.returned_device.battery1 }}
					</label>
				</div>
				<div class="return-equiptment">
					<label class="checklist-label">
						{{ $t('tgd1x.delivery.checklist.returned_device.battery2') + ': ' + checklistData.returned_device.battery2}}
					</label>
				</div>
				<div class="return-equiptment">
					<label class="checklist-label">
						{{ $t('tgd1x.delivery.checklist.returned_device.other') + ': ' + checklistData.returned_device.other}}
					</label>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ServiceChecklistRowPrint from '../ServiceChecklistRowPrint.vue'
import _indexOf from 'lodash/indexOf'
import { replaceDeepProperty } from '@utils/objectUtil'
function _defaultChecklistData() {
	return {
		general: {
			nut_bolt_test: undefined,
			nut_marking: undefined,
			holder_foam: undefined,
			z30_locking: undefined,
			z30_status: undefined,
			spray_lock_status: undefined,
			propeller_position: undefined,
			propeller_status: undefined,
			battery_lock_status: undefined,
			cable_condition: undefined,
			pump_condition: undefined,
			nozzle_model: '',
			nozzle_color: '',
			nozzle_flow_rate: '',
		},
		flight_manual: {
			hgmc_connection: undefined,
			hgmc_sync_compass: undefined,
			light_status_green: undefined,
			light_safety_red: undefined,
			power_on_stop_normal: undefined,
			power_on_stop_emergency: undefined,
			drone_stablity: undefined,
			drone_controller: undefined,
			camera_switching: undefined,
			pump_during_flight_control: undefined,
			tank_sensor_status: undefined,
			laser_altitude_control: undefined,
			vibration_x: '',
			vibration_y: '',
			vibration_z: '',
			laser_max_value_1: '',
			laser_max_value_2: '',
			power_off_stop_normal: undefined,
			nut_bolt_test: undefined,
		},
		flight_auto: {
			test1: {
				flight_plan_transfer: undefined,
				drone_stablity: undefined,
				start_plan: undefined,
				plan_path_correct: undefined,
				parking_water_by_plan: undefined,
				stop_auto: undefined,
			},
			test2: {
				flight_plan_transfer: undefined,
				drone_stablity: undefined,
				start_plan: undefined,
				plan_path_correct: undefined,
				parking_water_during_flight: undefined,
				stop_auto: undefined,
			},
		},
		returned_device: {
			is_rc_returned: false,
			is_tablet_returned: false,
			is_charger_returned: false,
			is_charging_panel_returned: false,
			battery1: undefined,
			battery2: undefined,
			other: undefined
		}
	}
}

export default {
	components: {
		ServiceChecklistRowPrint,
	},
	props: {
		service: {
			type: null,
			default: () => [],
		},
	},
	data() {
		return {
			checklistData: _defaultChecklistData(),
		}
	},
	computed: {
		generalCheckboxProps() {
			const excludedList = ['nozzle_model', 'nozzle_color', 'nozzle_flow_rate']
			return Object.keys(this.checklistData.general).filter((v) => _indexOf(excludedList, v) < 0)
		},
		flightManualCheckboxProps() {
			const excludedList = [
				'vibration_x',
				'vibration_y',
				'vibration_z',
				'laser_max_value_1',
				'laser_max_value_2',
				'power_off_stop_normal',
				'nut_bolt_test',
			]
			return Object.keys(this.checklistData.flight_manual).filter((v) => _indexOf(excludedList, v) < 0)
		},
	},
	methods: {
		setData(data) {
			this.checklistData = _defaultChecklistData()
			if (data) {
				replaceDeepProperty(this.checklistData, data)
			}
		}
	},
}
</script>

<style lang="less" scoped>
.pre_delivery-checklist {
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;

	.pre_delivery-checklist-block {
		flex-grow: 1;
		flex-basis: 325px;
		min-width: 325px;
		max-width: 400px;
		margin-bottom: 24px;
		padding-right: 16px;

		.mobile & {
			max-width: 100%;
			padding-right: 0;
			flex-basis: 275px;
			min-width: 275px;
		}
	}
	.checklist-custom {
		display: flex;
		justify-content: left;
		border-bottom: 1px dashed @gray-5;
		&:last-child {
			border-bottom: none;
		}
	}
	.return-equiptment {
		display: flex;
		justify-content: left;
		margin-top: 8px;
	}
	.checklist-pair {
		display: flex;
		justify-content: left;
		border-bottom: 1px dashed @gray-5;
		&:last-child {
			border-bottom: none;
		}
		.checklist-label {
			display: flex;
			flex-basis: 80%;
			justify-content: left;
		}
		.checklist-item {
			display: flex;
			flex-basis: 10%;
			justify-content: center;
		}
	}
}

@media print {
	.checklist-row {
		> label {
			-webkit-print-color-adjust: exact;
		}
	}
}
</style>
